const loadStyle = (path: string, fileName: string): Promise<void> =>
	new Promise((resolve: () => void, reject: (reason?: Error) => void): void => {
		let cleanFileName = fileName;
		if (cleanFileName.startsWith('/')) {
			cleanFileName = cleanFileName.substr(1);
		}
		const link = document.createElement('link');
		link.type = 'text/css';
		link.rel = 'stylesheet';
		link.href = `${path}/${cleanFileName}`;
		link.onload = resolve;
		link.onerror = (error): void => {
			console.error(error); // eslint-disable-line
			return reject(new Error(`Failed to load module style from url ${path}`));
		};
		document.head.appendChild(link);
	});

export default loadStyle;
