import React, { PropsWithChildren, useEffect, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import { Axios, HttpClient } from '@tunz/http';
import {
	AxiosPermissionsDataProvider,
	ErrorBoundary,
	PermissionsProvider,
} from '@tunz/ifs-react-lib';

import {
	loadAvailableModulesDefinitions,
	loadMenuItems,
	MenuItemDef,
	ModuleDef,
} from '../../Services/ModuleService';
import AsyncComponent from '../../Utils/AsyncComponent';
import Menu from '../Menu';
import Home from '../Home';
import Header from '../Header';
import ErrorDisplay from '../ErrorDisplay';
import { useWebBrand } from '../WebBrand';
import LogoutComponent from '../Login/LogoutComponent';
import { getUrlToOldBO, navigateToOldBOUrl } from '../../Services/NavigationService';
import RouteElement from './RouteElement';
import PathNotFound from '../PathNotFound';
import MicroFrontendPropsProvider from './MicroFrontendPropsProvider';
import WidgetsProvider from '../Widgets/WidgetsProvider';
import PasswordModificationContainer from '../Password/PasswordModificationContainer';

import '@tunz/message-displayer/dist/index.css';
import './Portal.css';
import '@tunz/ifs-mfe-menu/lib/style.css';

// eslint-disable-next-line no-shadow
export enum ResetOrChangePasswordEnum {
	Reset = 'RESET',
	Change = 'CHANGE',
}

type Props = PropsWithChildren & {
	childModuleName?: string;
};

const Portal: React.FC<Props> = ({ children, childModuleName = 'devmodule' }: Props) => {
	const { webBrand } = useWebBrand();

	const [moduleDefs, setModuleDefs] = useState<ModuleDef[]>([]);
	const [menuItems, setMenuItems] = useState<MenuItemDef[]>([]);
	const [errorMessage, setErrorMessage] = useState<string | undefined>();

	useEffect(() => {
		if (!children) {
			loadMenuItems()
				.then(setMenuItems)
				.catch((e) => setErrorMessage(e.message));
			loadAvailableModulesDefinitions()
				.then(setModuleDefs)
				.catch((e) => setErrorMessage(e.message));
		} else {
			const devMenuItems: MenuItemDef[] = [
				{
					label: 'devmodule',
					url: `https://dev.backoffice.tunz.com/${childModuleName}`,
					type: 'NEW',
				},
			];
			setMenuItems(devMenuItems);
			// This is needed to display the devmodule in the new menu
			window.menuItems = devMenuItems;
		}
	}, [children, childModuleName]);

	return (
		<PermissionsProvider
			provider={new AxiosPermissionsDataProvider(HttpClient as Axios.AxiosInstance)}
		>
			<WidgetsProvider modules={moduleDefs}>
				<Header>
					<Menu items={menuItems} />
				</Header>
				<div>
					<Routes>
						<Route path="/logout" element={<LogoutComponent />} />

						{/* If children is passed to the Portal, we are in development mode */}
						{children && (
							<Route
								path={`/${childModuleName}/*`}
								key={childModuleName}
								element={
									<ErrorBoundary name={childModuleName}>
										<MicroFrontendPropsProvider
											mfeProps={{
												name: childModuleName,
												httpClient: HttpClient as Axios.AxiosInstance,
												webBrand,
												navigateToOldBOUrl,
												getUrlToOldBO,
												location: {
													pathname: childModuleName,
													state: undefined,
													key: '',
													search: '',
													hash: '',
												},
											}}
										>
											{children}
										</MicroFrontendPropsProvider>
									</ErrorBoundary>
								}
							/>
						)}

						{/* If no children is passed to the Portal, get the mfes from UIBootstrap */}
						{!children &&
							moduleDefs.map((m) => {
								const AsyncComp = AsyncComponent(m);
								return (
									<Route
										path={`/${m.name}/*`}
										key={m.name}
										element={
											<RouteElement
												component={AsyncComp}
												name={m.name}
												httpClient={HttpClient as Axios.AxiosInstance}
												webBrand={webBrand}
												navigateToOldBOUrl={navigateToOldBOUrl}
												getUrlToOldBO={getUrlToOldBO}
											/>
										}
									/>
								);
							})}
						<Route path="/" element={<Home modules={moduleDefs} />} />
						<Route
							path="/changePassword"
							element={
								<PasswordModificationContainer
									resetOrChangePassword={ResetOrChangePasswordEnum.Change}
								/>
							}
						/>
						<Route path="*" element={<PathNotFound />} />
					</Routes>
					{errorMessage && (
						<ErrorDisplay
							message={`Unable to get the list of Microfrontends: ${errorMessage}`}
							fullPage
						/>
					)}
				</div>
			</WidgetsProvider>
		</PermissionsProvider>
	);
};

export default Portal;

// console.log('PortalReact', React);
// window.PortalReact = React;
// console.log(window.MenuReact === window.PortalReact); // true
