import { MicroFrontendDefinition, WidgetDefinition } from '.';

export const getMFEDefinition = (
	path: string,
	fileName: string,
	componentName: string,
): Promise<MicroFrontendDefinition> => {
	const loadModuleFromDOM =
		(
			resolve: (
				value: MicroFrontendDefinition | PromiseLike<MicroFrontendDefinition>,
			) => void,
			reject: (reason?: Error) => void,
		) =>
		() => {
			if (!window.microFrontends || !window.microFrontends[componentName]) {
				reject(new Error(`Unable to find module ${componentName} at ${path}`));
			}

			const definition: MicroFrontendDefinition = window.microFrontends[componentName];
			resolve(definition);
		};

	return new Promise((resolve, reject): void => {
		let cleanFileName = fileName;
		if (cleanFileName.startsWith('/')) {
			cleanFileName = cleanFileName.substr(1);
		}

		const script = document.createElement('script');
		script.src = `${path}/${cleanFileName}`;
		script.type = 'text/javascript';
		script.onload = loadModuleFromDOM(resolve, reject);
		script.onerror = (error): void => {
			console.error(error); // eslint-disable-line
			return reject(new Error(`Failed to download module from url ${path}`));
		};
		document.body.appendChild(script);
	});
};

export const getWidgetDefinition = (
	path: string,
	fileName: string,
	componentName: string,
): Promise<WidgetDefinition> => {
	const loadWidgetFromDOM =
		(
			resolve: (value: WidgetDefinition | PromiseLike<WidgetDefinition>) => void,
			reject: (reason?: Error) => void,
		) =>
		() => {
			if (!window.widgets || !window.widgets[componentName]) {
				reject(new Error(`Unable to find module ${componentName} at ${path}`));
			}

			const definition: WidgetDefinition = window.widgets[componentName];
			resolve(definition);
		};

	return new Promise((resolve, reject): void => {
		let cleanFileName = fileName;
		if (cleanFileName.startsWith('/')) {
			cleanFileName = cleanFileName.substr(1);
		}

		const script = document.createElement('script');
		script.src = `${path}/${cleanFileName}`;
		script.type = 'text/javascript';
		script.onload = loadWidgetFromDOM(resolve, reject);
		script.onerror = (error): void => {
			console.error(error); // eslint-disable-line
			return reject(new Error(`Failed to download module from url ${path}`));
		};
		document.body.appendChild(script);
	});
};

export default getMFEDefinition;
