/* eslint-disable jsx-a11y/click-events-have-key-events,
jsx-a11y/no-noninteractive-element-interactions */
import React, { PropsWithChildren, useContext, useState } from 'react';
import { Nav } from 'react-bootstrap';
import NewMenu from '@tunz/ifs-mfe-menu';

import './Header.scss';
import UserInfo from '../Login/UserInfo';
import LoginContext from '../Login/LoginContext';

import '@tunz/ifs-mfe-menu/lib/style.css';

type Props = PropsWithChildren<{ title?: string }>;

const MyLogoURL = 'https://prod.resources.tunz.com/worldline/logo-h-Pepper.png';

const MyColors = ['mint', 'coconut', 'pepper', 'blueberry', 'corn', 'fig', 'rhubarb', 'sage'];

const Header: React.FC<Props> = ({ title = '', children }: Props) => {
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [myIndex, setMyIndex] = useState(0);
	const [showNewMenu, setShowNewMenu] = useState(true);
	const { isLoggedIn } = useContext(LoginContext);

	const handleLogoClick: React.MouseEventHandler<HTMLImageElement> = (event) => {
		// Secret new menu feature: triple click on the logo to show the new / old menu
		// => can be used in development mode
		if (event.detail === 3) {
			setShowNewMenu(!showNewMenu);
		}
		// Easter egg to reactivate after the new menu transition is completed
		// if (myIndex + 1 > MyColors.length - 1) {
		// 	setMyIndex(0);
		// } else {
		// 	setMyIndex(myIndex + 1);
		// }
	};

	return (
		<div className="portal-header">
			<div className="header-first-row">
				<div className="header-left">
					{isLoggedIn && showNewMenu && <NewMenu newMenu />}
					<img
						src={MyLogoURL}
						className={`logo-${MyColors[myIndex]}`}
						alt="Worldline Logo"
						onClick={handleLogoClick}
						data-testid="header-logo"
					/>
				</div>
				<h1 className="header-title">{title}</h1>
				<UserInfo />
			</div>
			{!showNewMenu && <Nav className="mr-auto">{children}</Nav>}
		</div>
	);
};

export default Header;
