import React from 'react';
import ReactDOM from 'react-dom';
import { createRoot } from 'react-dom/client';
import * as ifsReactLib from '@tunz/ifs-react-lib';
import * as ifsHttp from '@tunz/http';
import * as ifsDataTables from '@tunz/data-tables';
import * as ReactRouter from 'react-router';
import * as ifsMessageDisplayer from '@tunz/message-displayer';
import * as ifsReferentials from '@tunz/referentials';
import * as ReactIcons from 'react-icons';

import { MenuItemDef, MicroFrontendDefinition, WidgetDefinition } from './Services/ModuleService';
import * as MfePortalExport from './MFEPortal';
import '@tunz/ifs-react-lib/dist/index.css';
import '@tunz/data-tables/dist/index.css';
import './index.css';
// import TestModule from './TestModule';

const MfePortal = MfePortalExport.default;

declare global {
	interface Window {
		IFS_ENV: {
			API_URL: string;
			LEGACY_BO_URL: string;
		};
		menuItems: MenuItemDef[] | undefined;
		microFrontends: { [key: string]: MicroFrontendDefinition };
		widgets: { [key: string]: WidgetDefinition };
		ReactDOM: typeof ReactDOM;
		ReactRouter: typeof ReactRouter;
		ifsReactLib: typeof ifsReactLib;
		ifsMfePortal: typeof MfePortalExport;
		ifsHttp: typeof ifsHttp;
		ifsDataTables: typeof ifsDataTables;
		ifsMessageDisplayer: typeof ifsMessageDisplayer;
		axios: typeof ifsHttp.Axios;
		ifsReferentials: typeof ifsReferentials;
		reactIcons: typeof ReactIcons;
	}
}

/** !! Do not remove those lines as these dependencies can be used
 *  by other MFEs !!  * */
window.React = React;
window.ReactDOM = ReactDOM;
window.ReactRouter = ReactRouter;
window.ifsReactLib = ifsReactLib;
window.ifsHttp = ifsHttp;
window.ifsMfePortal = MfePortalExport;
window.ifsDataTables = ifsDataTables;
window.ifsMessageDisplayer = ifsMessageDisplayer;
window.axios = ifsHttp.Axios;
window.ifsReferentials = ifsReferentials;
window.reactIcons = ReactIcons;

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
createRoot(document.getElementById('root')!).render(<MfePortal>{/* <TestModule /> */}</MfePortal>);
