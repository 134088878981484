import { useContext } from 'react';
import type { WebBrandContextValue } from './WebBrandContext';
import WebBrandContext from './WebBrandContext';
// import { WebBrand } from '../../Services/WebBrandService';

// export type WebBrandContext = {
// 	webBrand: WebBrand;
// 	switchWebBrand: (brandId: string) => void;
// };

const useWebBrand = (): WebBrandContextValue => {
	const { webBrand, switchWebBrand } = useContext(WebBrandContext);
	if (webBrand === undefined || webBrand.id === '0') {
		throw new Error("Cannot call 'useWebBrand' outside of a WebBrandProvider");
	}
	return { webBrand, switchWebBrand };
};

export default useWebBrand;
